<template>
  <v-chip
    data-cy="pinned-value"
    class="full-height"
    :color="$vuetify.theme.isDark ? undefined: 'white'"
    @click="$refs['incExcSwitch'].toggle($event)"
  >
    <div class="d-flex flex-row align-center">
      <inc-exc-switch
        ref="incExcSwitch"
        icon
        small
        :disabled="filterParamLoading"
        :value="incExcSwitchValue"
        @update:value="setSelectedValues(computedItem, $event)"
      />
      <div class="d-flex flex-column ml-2">
        <div>
          <span v-if="computedItem.id === null"> <v-icon small>{{$icon(`i.${toPascalCase(computedItem.type)}`)}}</v-icon> {{$t('t.None')}}</span>
          <document-picker-list-item-ref
            v-else-if="computedItem.id"
            :category-props="{ visible: false }"
            :item="computedItem"
            show-icon
          />
        </div>
        <div class="align-right">
          <v-tooltip
            open-delay=200
            left
            v-if="columnId"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <amt-column
                  v-if="Array.isArray(computedVal)"
                  :value="computedVal"
                  class="subtitle-2"
                />
                <balance-slash-amount-column
                  v-else-if="computedVal && (computedVal.amt || computedVal.bal)"
                  :value="computedVal"
                  class="subtitle-2 text-right"
                />
                <span
                  v-else-if="isPresentable(computedVal)"
                  class="subtitle-2"
                >{{computedVal}}</span>
              </div>
            </template>
            <document-picker-list-item-ref
              :category-props="{ visible: false }"
              :item="computedColumn"
            />
          </v-tooltip>
          <span
            v-else-if="isPresentable(computedVal)"
            class="subtitle-2"
          >{{computedVal}}</span>
        </div>
      </div>
      <slot
        name="pins"
        :item="computedItem"
      />
    </div>
  </v-chip>
</template>

<script>
import FilterParam from '@/pages/search/controllers/filter-param'

export default {
  components: {
    AmtColumn: () => import('@/pages/search/components/columns/amt-column'),
    BalanceSlashAmountColumn: () => import('@/pages/search/components/columns/balance-slash-amount-column'),
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    IncExcSwitch: () => import('./inc-exc-switch.vue')
  },
  computed: {
    computedItem () {
      return this.valItem?.[0]
    },
    computedVal () {
      return this.valItem?.[1]
    },
    computedColumn () {
      return { id: this.columnId, type: 'columns' }
    },
    incExcSwitchValue () {
      return this.filter.incExcSwitchValue(this.computedItem)
    }
  },
  data () {
    return {
      filterParamLoading: false
    }
  },
  methods: {
    isPresentable (v) {
      switch (typeof v) {
        case 'string':
        case 'number': return true
        default: return false
      }
    },
    async setSelectedValues (item, incExcSwitchValue) {
      if (!this.filterParamLoading) {
        this.filterParamLoading = true
        await this.$parent.setSelectedValues(item, incExcSwitchValue)
        this.filterParamLoading = false
      }
    }
  },
  props: {
    columnId: String,
    valItem: Array,
    filter: FilterParam
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.align-right
  align-self end
</style>
